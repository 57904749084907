import logo from '../../assets/layout/header.svg'
import mobileMenu from '../../assets/layout/mobileMenu.svg'
import MobileMenu from './mobileMenu'
import { useState } from 'react'
import { lock, unlock } from '../../utils/lockScreen'

const URL_SUBDOMAIN = process.env.REACT_APP_URL_SUBDOMAIN

export default function Header () {
  const [menu, setMenu] = useState(false)
  
  return (
  <>
    <MobileMenu menu={menu} setMenu={setMenu}/>
    <header className="h-[120px] w-[100vw] px-14 flex items-center justify-between bg-mainGradient gap-10 2xl:px-4 2xl:justify-center xl:flex-col xl:gap-2 lg:flex-row lg:justify-between lg:px-10 sm:px-5">
      <button onClick={() => {setMenu(!menu); menu ? unlock() : lock()}} className='px-1.5 hidden py-1 rounded bg-typo lg:block'>
        <img src={mobileMenu} alt="Mobile menu" className="w-7 h-auto sm:w-6 xs:w-5" />
      </button>
      <a href="https://www.scriptor.fr/" className='lg:absolute lg:-translate-x-1/2 lg:left-1/2'>
        <img src={logo} alt="Logo" className="h-12 w-auto xl:h-10 lg:h-[65px] sm:h-12 xs:h-10" />
      </a>
      <nav className="text-white gap-5 items-center flex text-[15px] font-bold tracking-[0.2px] xl:gap-4 whitespace-nowrap lg:hidden">
        <ul className="flex gap-5 items-center xl:gap-4">
          <li><a href="https://www.scriptor.fr/e-learning">E-learning</a></li>
          <li><a href="https://www.scriptor.fr/hotline">Hotline</a></li>
          <li><a href="https://www.scriptor.fr/expertises">Notre expertise</a></li>
          <li><a href="https://www.scriptor.fr/category/boite-outils">Boîte à outils</a></li>
          <li><a href={`${URL_SUBDOMAIN}/be-tutor/`}>Devenir tuteur</a></li>
        </ul>
        <a href={`${URL_SUBDOMAIN}/auth/`}>
          <button className='text-base relative text-typo px-4 py-2 rounded-[10px] border border-yellow/90 bg-yellow/90 hover:scale-105 transition-all duration-300 hover:bg-yellow hover:border-yellow'>Se connecter
          </button>
        </a>
        <a href="https://www.scriptor.fr/contact">
          <button className='text-base relative px-4 py-2 border border-white rounded-[10px] bg-transparent hover:scale-105 transition-all duration-300 hover:bg-white/10'>Contact
          </button>
        </a>
      </nav>
    </header>
  </>
  )
}