import { CircularLoading } from "../loader"
import { useEffect, useRef } from "react";
import Star from '../../assets/elearning/star.svg'
import YellowStar from '../../assets/elearning/yellowStar.svg'

import { register } from "swiper/element/bundle";
register();


export function Reviews2 ({reviewsDetails}) {
    const swiperRef = useRef(null);  
    useEffect(() => {
      const swiperContainer = swiperRef.current;
      Object.assign(swiperContainer, swiperParams);
      swiperContainer.initialize();
    }, []);
    return(
        <swiper-container ref={swiperRef} init="false" class='w-full max-w-[1400px]'>
        {reviewsDetails?.map(r => 
            <SlideReview key={r.id} review={r} />
        )}
        </swiper-container>
    )
}

export function SlideReview ({review}) {
    return(
      <swiper-slide class="flex flex-col items-center">
        <div className="max-w-[300px] w-full flex flex-col gap-5 bg-[#E5E5EA] min-h-[276px] justify-center px-10 pb-5 pt-14 relative md:max-w-[270px]">
            <p className="text-7xl leading-[36px] font-bold absolute top-9 left-7">“</p>
            <p className="line-clamp-6 text-ellipsis">{review.comment ? review.comment : ""}</p>
            <div className="flex gap-2 items-center text-sm font-bold">
            {review?.mark ?
                <>
                    <div className='flex'>
                    {(() => {
                    return(
                        !isNaN(review?.mark) && Math.ceil(review?.mark, 10) > 0 &&
                            Array(Math.ceil(review?.mark, 10))
                                .fill(0)
                                .map(() => <img key={Math.random()} src={YellowStar} alt="Evaluation" className='w-4 h-auto'/>)
                    )
                    })()}
                    {(() => {
                    return(
                        !isNaN(5-review?.mark) && Math.floor(5-review?.mark, 10) > 0 &&
                            Array(Math.floor(5-review?.mark, 10))
                                .fill(0)
                                .map(() => <img key={Math.random()} src={Star} alt="Evaluation" className='w-4 h-auto'/>)
                    )
                    })()}
                    </div>
                    <p>{review?.mark}/5</p>
                </>
                : <CircularLoading />
                }
            </div>
        </div>
        <div className="max-w-[300px] w-full font-medium text-white text-lg bg-[#FAAF3A] font-Poppins flex justify-center text-center py-5 md:max-w-[270px]">
            <p>{review?.firstname ? review?.firstname.toUpperCase() : ''}</p>
        </div>
      </swiper-slide>
    )
  }

  export function StarBasic ({rate}) {
    return(
        <div className='flex'>
            {(() => {
                return(
                    !isNaN(rate) && Math.ceil(rate, 10) > 0 &&
                        Array(Math.ceil(rate, 10))
                            .fill(0)
                            .map(() => <img key={Math.random()} src={YellowStar} alt="Evaluation" className='w-4 h-auto'/>)
                )
                })()}
                {(() => {
                return(
                    !isNaN(5-rate) && Math.floor(5-rate, 10) > 0 &&
                        Array(Math.floor(5-rate, 10))
                            .fill(0)
                            .map(() => <img key={Math.random()} src={Star} alt="Evaluation" className='w-4 h-auto'/>)
                )
            })()}
        </div>
    )
  }


  const swiperParams = {
    navigation:true,
    spacebetween:40,
    slidesPerView: 4,
    breakpoints:{
        0: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 0,
        },
        1300: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
    },
    injectStyles: [
        `
        .swiper-button-next,
        .swiper-button-prev {
            color: #15301F;
            width: 14px;
            height: auto;
        }
        .swiper-pagination-bullet{
            background-color: #15301F;
        }
    `,
    ],
  };

  export const swiperParams3 = {
    navigation:true,
    spacebetween:40,
    slidesPerView: 4,
    autoplay:{
        delay: 10000,
        disableOnInteraction: false,
    },
    breakpoints:{
        0: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
    },
    injectStyles: [
        `
        .swiper-button-next,
        .swiper-button-prev {
            color: #15301F;
            width: 14px;
            height: auto;
        }
        .swiper-button-prev {
            left:0px;
        }
        .swiper-button-next {
            right:0px;
        }
        .swiper-pagination-bullet{
            background-color: #15301F;
        }
    `,
    ],
  };