import footer from '../../assets/layout/footer.svg'

export default function Footer () {
    return (
        <footer className="tracking-[0.2px] w-[100vw] bg-mainGradient">
            <section className='text-white grid grid-cols-4 pt-14 justify-items-center lg:grid-cols-3 sm:pt-10 sm:flex sm:flex-col sm:justify-items-start sm:gap-10'>
                <div className='flex flex-col gap-5 sm:gap-3 sm:items-center sm:mx-5'>
                    <h2 className='font-bold text-xl'>Mémoire</h2>
                    <ul className="flex text-lg flex-col gap-2.5 md:text-base sm:flex-row sm:gap-x-7 sm:gap-y-2 sm:flex-wrap sm:justify-center">
                        <li><a href="https://www.scriptor.fr/services/redaction-de-memoire-dec-expertise-comptable">Mémoire DEC</a></li>
                        <li><a href="https://www.scriptor.fr/services/redaction-de-memoire-vae">VAE</a></li>
                        <li><a href="https://www.scriptor.fr/services/dossier-raep">Dossier RAEP</a></li>
                        <li><a href="https://www.scriptor.fr/services/tfe-infirmier">TFE Infirmier</a></li>
                        <li><a href="https://www.scriptor.fr/services/redaction-de-these">Thèse</a></li>
                    </ul>
                </div>
                <div className='flex flex-col gap-5 sm:gap-3 sm:items-center sm:mx-5'>
                    <h2 className='font-bold text-xl'>Liens utiles</h2>
                    <ul className="flex text-lg flex-col gap-2.5 md:text-base sm:flex-row sm:gap-x-7 sm:gap-y-2 sm:flex-wrap sm:justify-center">
                        <li><a href="https://www.scriptor.fr/faq">FAQ</a></li>
                        <li><a href="https://www.scriptor.fr/category/boite-outils">Boite à outils</a></li>
                    </ul>
                </div>
                <div className='flex flex-col gap-5 sm:gap-3 sm:items-center sm:mx-5'>
                    <h2 className='font-bold text-xl'>Nos offres</h2>
                    <ul className="flex text-lg flex-col gap-2.5 md:text-base sm:flex-row sm:gap-x-7 sm:gap-y-2 sm:flex-wrap sm:justify-center">
                        <li><a href="https://www.scriptor.fr/e-learning">E-learning</a></li>
                        <li><a href="https://www.scriptor.fr/hotline">Hotline</a></li>
                    </ul>
                </div>
                <img src={footer} alt="Logo" className="bg-transparent lg:hidden" />
            </section>
            <section className='py-4 grid grid-cols-4 justify-items-center border-t border-[#F1F3F34D] mt-16 md:flex md:justify-between md:px-5 md:mt-10'>
                <h1 className='text-lg font-bold text-white'>Scriptor</h1>
            </section>
        </footer>
    )
}
