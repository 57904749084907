import { unlock } from "../../utils/lockScreen"
import Close from "../../assets/layout/close.svg"

const URL_SUBDOMAIN = process.env.REACT_APP_URL_SUBDOMAIN

export default function MobileMenu ({menu, setMenu}) {
    const closeBurger = () => {setMenu(false);unlock()}

    return (
        <>
            <div className="hidden fixed w-full h-full left-0 top-0 z-30 bg-black/60 cursor-pointer lg:block"
                    style={menu ? {opacity:1, transition:'opacity 1s'} : {opacity:0, zIndex:-10}} onClick={() => {setMenu(false);unlock()}}></div>
                <menu className="hidden max-w-[450px] fixed pl-10 w-[60%] pr-10 h-full top-0 text-white bg-white z-40 py-16 flex-col items-center lg:flex sm:w-full sm:pr-10 lg:py-10 bg-mainGradient"
                style={menu ? {left:0, transition:'left 400ms ease-out'} : {left:"-100%"}}>
                <img src={Close} onClick={() => {setMenu(false);unlock()}} alt="Close pictogram" className='w-8 cursor-pointer self-start' />
                <div className="flex mt-20 w-full md:mt-10">
                    <ul className="relative flex flex-col items-center text-2xl tracking-wide w-full gap-7 font-medium md:text-base md:gap-5">
                        <li onClick={closeBurger} className="border-b border-gray-200 w-full pb-2 hover:pl-3 transition-all duration-300"><a href="https://www.scriptor.fr/e-learning">E-learning</a></li>
                        <li onClick={closeBurger} className="border-b border-gray-200 w-full pb-2 hover:pl-3 transition-all duration-300"><a href="https://www.scriptor.fr/hotline">Hotline</a></li>
                        <li onClick={closeBurger} className="border-b border-gray-200 w-full pb-2 hover:pl-3 transition-all duration-300"><a href="https://www.scriptor.fr/expertises">Notre expertise</a></li>
                        <li onClick={closeBurger} className="border-b border-gray-200 w-full pb-2 hover:pl-3 transition-all duration-300"><a href="https://www.scriptor.fr/category/boite-outils">Boîte à outils</a></li>
                        <li onClick={closeBurger} className="border-b border-gray-200 w-full pb-2 hover:pl-3 transition-all duration-300"><a href={`${URL_SUBDOMAIN}/be-tutor/`}>Devenir tuteur</a></li>
                        <a onClick={closeBurger} href={`${URL_SUBDOMAIN}/auth/`} className="place-self-start">
                            <button className='text-typo text-2xl md:text-base relative px-6 mt-3 md:mt-0 py-2 rounded-[10px] bg-yellow/90 hover:scale-105 transition-all duration-300 hover:bg-yellow'>Se connecter
                            </button>
                        </a>
                        <a onClick={closeBurger} href="https://www.scriptor.fr/contact" className="place-self-start">
                            <button className='text-white text-2xl md:text-base relative border border-white px-6 mt-3 md:mt-0 py-2 rounded-[10px] bg-transparent hover:scale-105 transition-all duration-300 hover:bg-white/10'>Contact
                            </button>
                        </a>
                    </ul>
                </div>
            </menu>
        </>
    )
}