import { createTheme } from "@mui/material";

export const colors = {
    primary: "#c0bdf6",
    secondary: "#e9e8fc",
    tertiary: "#FDB636",
    typo: "#212427",
    input:"#5983FC",
    interface:"#409FC3"
}

export const colorTheme = createTheme({
    palette: {
        primary: {
            main: colors.tertiary,
        },
        secondary: {
            main: colors.tertiary,
        },
    }
    
})

export const interfaceTheme = createTheme({
    palette: {
        primary: {
            main: colors.interface,
        },
    }
    
})


// WebkitBoxShadow: "0 0 0 1000px white inset"
export const nobgCompletion = { spellcheck: 'false' };