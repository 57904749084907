import Layout from "./components/layout/layout"
import { LazyYoutubeModal } from "./utils/youtube"
import {GETRequest} from './utils/requestHeader'
import Skeleton from '@mui/material/Skeleton';

import BG from './assets/elearning/background.svg'
import { round_amount } from "./utils/helpFunction"
import Star from './assets/elearning/star.svg'
import Open from './assets/elearning/open.svg'
import Time from './assets/elearning/time.svg'
import Video from './assets/elearning/video.svg'
import BlueVideo from './assets/elearning/video_blue.svg'
import Clock from './assets/elearning/clock.svg'
import Support from './assets/elearning/support.svg'

import Brain from './assets/elearning/brain.svg'
import Hand from './assets/elearning/hand.svg'
import Coach from './assets/elearning/coach.svg'
import Method from './assets/elearning/method.svg'

import gray_0 from './assets/elearning/gray_0.svg'
import gray_1 from './assets/elearning/gray_1.svg'
import gray_2 from './assets/elearning/gray_2.svg'
import gray_3 from './assets/elearning/gray_3.svg'
import gray_4 from './assets/elearning/gray_4.svg'

import contactSVG from './assets/elearning/contactSVG.svg'

import Prep from './assets/elearning/Prep.svg'
import YellowStar from './assets/elearning/yellowStar.svg'

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { useCallback, useEffect, useState } from "react"
import dataFilter from './assets/data/data.json'
import { Reviews2, StarBasic } from "./components/elearning/review"
import { CircularLoading } from "./components/loader"

const API_URL = process.env.REACT_APP_API_URL
const URL_SUBDOMAIN = process.env.REACT_APP_URL_SUBDOMAIN

export default function App() {
  const [formations, setFormations] = useState(null)
  const [formationNoFilter, setFormationNoFilter] = useState()
  const [exampleFormations, setExampleFormations] = useState()
  const [categoryTitle, setCategoryTitle] = useState('Voir tout')
  const [reviews, setReviews] = useState()    

  const lazy_reviews = useCallback(async () => {
      const formations = await fetch(`${API_URL}/api/elearning`, GETRequest).then(r => r.json())
      setFormations(formations)
      setFormationNoFilter(formations)
      setExampleFormations(formations)
      const response = await fetch(`${API_URL}/api/reviews`, GETRequest).then(r => r.json())
      setReviews(response)
  }, [])

  useEffect(() => {
      lazy_reviews().catch(console.error);
  }, [lazy_reviews])

  return (
    <>
    <Layout>
        <main className="w-screen flex flex-col items-center text-typo px-10 lg:px-5">
            <section className="flex w-full items-center py-20 gap-5 max-w-[1400px] md:flex-col md:gap-10">
                <div className="flex flex-col gap-10 w-1/2 md:w-full md:text-center md:items-center md:gap-5">
                    <div className="w-fit flex flex-col gap-10 md:gap-5">
                        <h1 className="font-Poppins text-5xl font-semibold lg:text-4xl">E-LEARNING</h1>
                        <div className="h-1 bg-yellow w-[70%] min-[100px] md:w-full"></div>
                    </div>
                    <p className="lg:text-sm">Nos modules de formation e-learning proposent une méthodologie inédite pour rédiger le mémoire de recherche et des astuces pour exploiter au mieux les ressources offertes par l’IA </p>
                    <a href="#modules">
                        <button className='text-xl font-semibold relative text-white px-6 py-3 rounded-[10px] bg-yellow hover:scale-105 transition-all duration-300 lg:text-lg lg:px-5 lg:py-2.5'>Découvrir</button>
                    </a>
                </div>
                <div className="w-1/2 flex justify-center relative md:w-full">
                    <LazyYoutubeModal>
                        <iframe
                            className="w-[45vw] max-w-[650px] h-[25vw] max-h-[400px] rounded-xl md:w-[70vw] md:h-[40vw] sm:w-[95vw] sm:h-[50vw]"
                            src="https://www.youtube.com/embed/NybO-XlyHv0?si=hrNiSwKhCmIi6ekr"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                            ></iframe>
                    </LazyYoutubeModal>
                </div>
            </section>
            <section id="modules" className="bg-[#F6F6F6] py-10 md:py-5 w-[100vw] px-20 lg:px-10 sm:px-5">
                <h2 className="text-3xl font-Poppins font-semibold">Explorez nos modules à la carte</h2>
                <div className="flex py-20 justify-center gap-10 relative lg:flex-col lg:py-5 lg:gap-5">
                    <CustomMenu setFormations={setFormations} listNoFilter={formationNoFilter} setCategoryTitle={setCategoryTitle} categoryTitle={categoryTitle} />
                    <div className="sticky top-20 h-fit flex flex-col w-[250px] min-w-[250px] lg:hidden">
                        <h3 className="font-bold text-xl">Filtres</h3>
                        <div className="flex flex-col font-medium gap-2 border-y border-typo py-4 my-4">
                            <p  className="hover:bg-typo/5 p-1 rounded-md cursor-pointer transition-all duration-300" 
                                onClick={() => {setFormations(formationNoFilter);setCategoryTitle('Voir tout')}} 
                                style={categoryTitle === 'Voir tout' ? {color:'#FDB636', fontWeight:'semiBold'} : {}}>
                                Voir tout
                            </p>
                            {dataFilter?.categories?.map(category => 
                                <p  key={category.id} 
                                    onClick={
                                        () => {setFormations(formationNoFilter.filter(formation => formation?.categories?.includes(category.title))); setCategoryTitle(category.title)}
                                    } 
                                    className="hover:bg-typo/5 p-1 rounded-md cursor-pointer"
                                    style={categoryTitle === category.title ? {color:'#FDB636', fontWeight:'semi-bold'} : {}}>
                                    {category.title}
                                </p>)}
                        </div>
                    </div>
                    <div className="grid grid-cols-[repeat(auto-fill,_minmax(280px,_1fr))] gap-5 w-full 3xl:grid-cols-3 3xl:max-w-[1200px]">
                        {formations 
                            ? formations?.filter(formation => formation.id !== 1).map(formation => 
                                <EleaningCard key={formation.id} formation={formation} />
                            )
                            : [...Array(6).keys()].map(skeletCard => <Skeleton key={skeletCard} variant="rectangular" sx={{ height:'400px', borderRadius:"8px" }} />)
                        }
                    </div>
                </div>
            </section>
            <section className="py-14 md:py-7">
                <p className="text-[#E5E5EA] font-semibold text-3xl text-center lg:text-2xl sm:text-xl">Découvre notre module gratuit</p>
            </section>
            <section className="bg-[#F6F6F6] py-10 md:py-5 w-[100vw] px-10 sm:px-5">
                <div className="flex gap-4">
                    <div className="rounded-full w-16 h-16 border border-[#052A4426] flex items-center justify-center sm:hidden">
                        <img src={Prep} className='w-3/4 h-auto' alt='Scriptor logo' aria-hidden="true" />
                    </div>
                    <div className="flex flex-col justify-between gap-3">
                        <h2 className="text-lg font-medium sm:text-base sm:text-center">Module gratuit: La finalisation du mémoire et l’IA</h2>
                        <div className="flex gap-4 gap-y-2 items-center sm:text-sm sm:flex-wrap sm:justify-center">
                            <div className="flex gap-2 items-center">
                                <img src={Video} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>4 vidéos</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <img src={Support} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>2 supports</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <img src={Time} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>Accès complet</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-10 py-10 xl:gap-5 lg:flex-col md:py-5">
                    <div className="flex justify-center relative md:w-full">
                        <LazyYoutubeModal>
                            <iframe
                                className="w-[50vw] max-w-[750px] h-[30vw] max-h-[400px] rounded-xl md:w-[70vw] md:h-[40vw] sm:w-[95vw] sm:h-[50vw]"
                                src="https://www.youtube.com/embed/rfZrBxHrhzs?si=d9_Ud0L8eit3Hwtk"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                ></iframe>
                        </LazyYoutubeModal>
                    </div>
                    <div className="flex-1 flex flex-col gap-10 sm:gap-5 xl:gap-5">
                        <div className="flex flex-col bg-white border border-[#E9EAF0] divide-y rounded-md xl:text-sm">
                            <div className="flex gap-3 p-5 items-center w-full justify-between xl:p-3">
                                <img src={BlueVideo} alt='Informations' className="" aria-hidden="true" />
                                <p className="text-yellow font-medium flex-1">La méthodologie de rédaction de l’introduction & de la conclusion du mémoire</p>
                                <div className="flex items-center gap-2">
                                    <img src={Clock} alt='Informations' className="" aria-hidden="true" />
                                    <p className="text-yellow font-medium">6m</p>
                                </div>
                            </div>
                            <div className="flex gap-3 p-5 items-center w-full justify-between xl:p-3">
                                <img src={BlueVideo} alt='Informations' className="" aria-hidden="true" />
                                <p className="text-yellow font-medium flex-1">La correction orthographique, syntaxique & la mise en page du mémoire</p>
                                <div className="flex items-center gap-2">
                                    <img src={Clock} alt='Informations' className="" aria-hidden="true" />
                                    <p className="text-yellow font-medium">4m</p>
                                </div>
                            </div>
                            <div className="flex gap-3 p-5 items-center w-full justify-between xl:p-3">
                                <img src={BlueVideo} alt='Informations' className="" aria-hidden="true" />
                                <p className="text-yellow font-medium flex-1">Comment utiliser l’IA pour rédiger l’introduction et la conclusion du mémoire ?</p>
                                <div className="flex items-center gap-2">
                                    <img src={Clock} alt='Informations' className="" aria-hidden="true" />
                                    <p className="text-yellow font-medium">6m</p>
                                </div>
                            </div>
                            <div className="flex gap-3 p-5 items-center w-full justify-between xl:p-3">
                                <img src={BlueVideo} alt='Informations' className="" aria-hidden="true" />
                                <p className="text-yellow font-medium flex-1">Préconisations d’usage de l’IA pour finaliser son mémoire</p>
                                <div className="flex items-center gap-2">
                                    <img src={Clock} alt='Informations' className="" aria-hidden="true" />
                                    <p className="text-yellow font-medium">2m</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#E9EAF0] rounded-md bg-white flex flex-col gap-4 p-5 xl:text-sm">
                            <div className='flex'>
                                {(() => {
                                return(
                                    !isNaN(5) && parseInt(5, 10) > 0 &&
                                        Array(parseInt(5, 10))
                                            .fill(0)
                                            .map(() => <img key={Math.random()} src={YellowStar} alt="Evaluation" className='w-6 h-auto xl:w-4'/>)
                                )
                                })()}
                                {(() => {
                                return(
                                    !isNaN(5-5) && parseInt(5-5, 10) > 0 &&
                                        Array(parseInt(5-5, 10))
                                            .fill(0)
                                            .map(() => <img key={Math.random()} src={Star} alt="Evaluation" className='w-6 h-auto xl:w-4'/>)
                                )
                                })()}
                            </div>
                            <p>Merci pour ces précieux conseils et ces super outils qui m’ont bien aidée dans la dernière ligne droite!</p>
                            <a href={`${URL_SUBDOMAIN}/e-learning/la-finalisation-du-memoire-et-ia`} className="px-5 py-3 text-white text-base w-fit place-self-end bg-yellow rounded-xl font-semibold xl:px-4 xl:py-2 xl:text-sm xl:mt-2">Visionner</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-mainGradient w-[100vw] flex justify-center">
                <div className="flex h-[680px] gap-10 items-center pl-10 text-white relative w-full max-w-[1400px] py-20 lg:h-full lg:flex-col-reverse lg:py-10 lg:px-10 sm:px-5">
                    <div className="flex flex-col gap-5 w-fullPackage lg:w-full">
                        <p className="tracking-wider">ECONOMISE PRÈS DE 50%</p>
                        <div className="flex flex-col font-bold text-3xl lg:text-2xl sm:text-xl">
                            <p className="text-yellow">Un pack complet </p>
                            <p>pour t’accompagner, à chaque étape du mémoire, te guider et te former.</p>
                        </div>
                        <div className="flex gap-5 text-2xl font-bold pt-5 sm:text-xl">
                            <p className="line-through decoration-[#FF0571]">70.30€</p>
                            <p>39.90€</p>
                        </div>
                        <a href={`${URL_SUBDOMAIN}/e-learning/maitrise-des-fonctionnalites-de-ia-pour-rediger-un-memoire-de-recherche`} className="px-10 py-3 text-typo text-base w-fit place-self-end bg-[#F8C011] rounded-xl font-semibold xl:px-4 xl:py-2 xl:text-sm xl:mt-2 lg:place-self-center">Acheter le pack</a>
                        <div className="flex gap-4 gap-y-2 items-center pb-5 border-b border-white sm:text-sm flex-wrap sm:justify-center sm:border-none sm:pb-0">
                            <div className="flex gap-2 items-center">
                                <img src={Video} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>53 vidéos</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <img src={Support} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>11 outils</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <img src={Time} alt='Informations' className="pb-1" aria-hidden="true" />
                                <p>Accèssible à ton rythme</p>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[520px] h-full lg:h-[340px] sm:w-[340px]">
                        <div className="absolute w-full h-full">
                        {exampleFormations ? 
                            <>
                                <div className="w-[240px] absolute text-typo right-0 top-28 sm:hidden">
                                    <EleaningCardFullPackage image={BG} formation={exampleFormations[2]} rate={4} />
                                </div>
                                <div className="w-[240px] absolute top-20 left-0 text-typo">
                                    <EleaningCardFullPackage image={BG} formation={exampleFormations[3]} rate={4} />
                                </div>
                                <div className="w-[240px] absolute top-6 left-1/2 -translate-x-1/2 text-typo sm:left-full sm:-translate-x-full">
                                    <EleaningCardFullPackage image={BG} formation={exampleFormations[4]} rate={4} />
                                </div>
                            </>
                            : <CircularLoading />
                        }
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-[100vw] flex justify-center bg-[#F1F3F3] py-20 lg:py-10">
                <div className="w-full px-10 max-w-[1240px] flex ml-16 justify-center xl:max-w-[980px] lg:flex-col lg:w-full lg:gap-5 lg:px-20 md:px-10 sm:px-3">
                    <RoundHelp image={gray_0}  title="Gagne en performance" text="Maîtrise la méthodologie du mémoire de recherche" />
                    <RoundHelp image={gray_1} title="Gagne en productivité" text="Maîtrise les fonctionnalités et limites de l'IA" />
                    <RoundHelp image={gray_2} title="Gagne en efficacité" text="Des modules dynamiques à l'appui d'exemples" />
                    <RoundHelp image={gray_3} title="Gagne en temps" text="Une formation d'où tu veux, quand tu veux, à ton rythme" />
                    <RoundHelp image={gray_4} title="Garantie de qualité académique" text="Une formation adaptée à toutes les disciplines des sciences humaines" />
                </div>
            </section>
            <section className="pt-16 md:pt-5 w-[100vw] flex justify-center bg-[#F1F3F3] px-10 sm:px-0">
                <div className=" max-w-[1400px] flex gap-10 py-10 lg:flex-col md:py-5">
                    <div className="flex justify-center relative md:w-full">
                        <LazyYoutubeModal>
                            <iframe
                                className="w-[45vw] max-w-[750px] h-[25vw] max-h-[400px] rounded-xl lg:w-[70vw] lg:h-[38vw] sm:w-[95vw] sm:h-[50vw]"
                                src="https://www.youtube.com/embed/bIRyalADXIA?si=qz-FLEPmPvO5HmoM"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                ></iframe>
                        </LazyYoutubeModal>
                    </div>
                    <div className="w-full flex-1 flex flex-col gap-10 md:gap-5 sm:px-5">
                        <h1 className="font-bold text-5xl underline underline-offset-4 decoration-[10px] decoration-[#3EC1D3] lg:text-4xl md:text-3xl md:decoration-[5px] sm:text-2xl">HOTLINE</h1>
                        <p className="text-xl font-bold md:text-lg">Quel que soit ton besoin, un universitaire est à ton écoute</p>
                        <div className="text-base flex flex-col gap-4 -mt-5 md:mt-0 sm:text-sm"> 
                            <div className="flex gap-3 items-center w-full">
                                <img src={Hand} alt='Informations' className="w-7 h-auto sm:w-6" aria-hidden="true" />
                                <p>Une assistance individuelle, personnalisée et en live</p>
                            </div>
                            <div className="flex gap-3 items-center w-full">
                                <img src={Method} alt='Informations' className="w-7 h-auto sm:w-6" aria-hidden="true" />
                                <p>Pour la méthodologie du mémoire de recherche</p>
                            </div>
                            <div className="flex gap-3 items-center w-full">
                                <img src={Brain} alt='Informations' className="w-7 h-auto sm:w-6" aria-hidden="true" />
                                <p>Pour une aide à l’utilisation académique de l’IA</p>
                            </div>
                            <div className="flex gap-3 items-center w-full">
                                <img src={Coach} alt='Informations' className="w-7 h-auto sm:w-6" aria-hidden="true" />
                                <p>Pour un coaching à la soutenance orale, etc.</p>
                            </div>
                        </div>
                        <a href="https://www.scriptor.fr/hotline" className="px-6 py-3 text-typo text-base w-fit place-self-end bg-yellow rounded-xl font-semibold md:mt-5 lg:place-self-center">En savoir plus</a>
                    </div>
                </div>
            </section>
            <section className="w-[100vw] bg-elearningGradient py-20 flex flex-col gap-16 px-10">
                <div className="flex flex-col justify-center text-center text-white">
                    <p className="font-semibold text-lg md:text-base">Témoignages</p>
                    <h3 className="font-Poppins font-semibold text-4xl lg:text-3xl md:text-2xl">Nos avis c’est la qualité garantie</h3>
                </div>
                <Reviews2 reviewsDetails={reviews} />
            </section>
            <section className="flex gap-8 justify-center items-center w-full py-20 md:py-10 md:flex-col">
                <img src={contactSVG} alt='Informations' className="w-auto h-[400px] md:h-[280px]" aria-hidden="true" />
                <div className="flex flex-col gap-5">
                    <h2 className="text-3xl md:text-2xl font-Poppins font-semibold text-center">Vous avez un besoin spécifique ?</h2>
                    <a href='https://www.scriptor.fr/contact' className="px-6 py-3 text-typo text-base w-fit bg-yellow rounded-xl font-semibold place-self-center">Nous contacter</a>
                </div>
            </section>
        </main>
    </Layout>
</>
)
}

export function RoundHelp ({image, title, text}) {
return(
<div className="group -ml-16 rounded-full overflow-hidden bg-white hover:grow-[4] 
                hover:delay-0 transition-all  
                delay-100 duration-700
                flex items-center relative lg:hover:grow lg:overflow-visible lg:bg-transparent lg:gap-5 sm:gap-3">
    <div className="flex items-center justify-center 
                    min-w-[230px] min-h-[230px] max-w-[230px] max-h-[230px] 
                    xl:min-w-[180px] xl:min-h-[180px] xl:max-w-[180px] xl:max-h-[180px] 
                    lg:min-w-[130px] lg:min-h-[130px] lg:max-w-[130px] lg:max-h-[130px] 
                    sm:min-w-[110px] sm:min-h-[110px] sm:max-w-[110px] sm:max-h-[110px]">
        <img src={image} className='w-full h-full object-cover rounded-full' alt='Pictogram e-learning' />
    </div>
    <div className="h-full rounded-x-full absolute top-1/2 -translate-y-1/2 opacity-0 
                    group-hover:duration-300 group-hover:delay-300 group-hover:opacity-100
                    duration-300 delay-0 transition-all
                    flex flex-col justify-center gap-3 
                    left-[240px] w-[240px] 
                    xl:left-[190px]
                    lg:opacity-100  lg:w-full lg:static lg:-translate-y-0 sm:gap-1">
        <h3 className="text-lg font-bold lg:text-xl sm:text-base">{title}</h3>
        <p className="text-sm text-gray-600 font-medium lg:text-base sm:text-sm">{text}</p>
    </div>
</div>
)
}

export function EleaningCard ({formation}) {

return(
<div className="bg-white flex flex-col rounded-lg h-fit relative">
    <div className="w-full p-2 h-[180px] relative">
        <img src={formation?.image?.url_preview} alt="Thumbnail" fill="true" className="w-full h-full object-cover rounded-md" />
        {formation.id === 1 
            ? <div className="absolute px-3 py-1.5 bg-yellow rounded-md flex items-center justify-center text-white text-sm right-4 top-4 font-semibold">
                <p>Formation complète</p>
            </div>
            : ''
        }
    </div>
    {formation?.is_available !== 1 
        ? <div className="p-4 flex flex-col gap-2 w-full h-[220px]">
            <h3 className="font-bold text-lg text-ellipsis line-clamp-1">{formation?.name}</h3>
            {formation?.description 
                ? <p className="text-[#0000008A] text-sm font-medium text-ellipsis line-clamp-3 flex-1">{formation?.description}</p>
                : <p className="flex-1"></p>
            }
            <button disabled className="w-full font-bold rounded-xl text-white bg-gray-200 text-center flex justify-center py-2.5 md:text-sm">
                {`Arrive prochainement`}
            </button>
        </div>
        : <div className="p-4 flex flex-col gap-2 w-full h-[220px]">
            <h3 className="font-bold text-lg text-ellipsis line-clamp-1">{formation?.name}</h3>
            {formation?.description 
                ? <p className="text-[#0000008A] text-sm font-medium text-ellipsis line-clamp-3 flex-1">{formation?.description}</p>
                : <p className="flex-1"></p>
            }

            <div className="flex justify-between">
                <StarBasic rate={(formation?.modules?.reduce((accumulator, currentValue) => accumulator + ((Number(currentValue.average_rating))), 0)/formation?.modules?.length)} />
                <div className="text-lg font-bold">{formation?.price === 0 ? "Gratuit" : `${round_amount(formation?.price)}€`}</div>
            </div>
            <a href={`${URL_SUBDOMAIN}/e-learning/${formation?.slug}`} className="w-full font-bold rounded-xl text-[#00BCD4] border border-[#00BCD4] text-center flex justify-center py-2.5 md:text-sm">
                {`Découvrir`}
            </a>
        </div>
    }
</div>
)
}

export function EleaningCardFullPackage ({formation}) {
return(
<div className="bg-white flex flex-col rounded-lg shadow-xl">
    <div className="w-full p-2 h-[150px] relative">
        <img src={formation?.image?.url_preview} alt="Thumbnail" fill="true" className="object-cover rounded-md" />
    </div>
    <div className="px-4 pb-4 pt-2 flex flex-col gap-2 w-full">
        <h3 className="font-bold text-base">{formation?.name}</h3>
        <p className="text-[#0000008A] text-xs font-medium">{formation?.description}</p>
        {/* <div className="flex justify-between"> */}
            {/* <StarBasic rate={(formation?.modules?.reduce((accumulator, currentValue) => accumulator + ((Number(currentValue.average_rating))), 0)/formation?.modules?.length)} /> */}
            {/* <div className="text-base font-bold">{round_amount(formation?.price)}€</div> */}
        {/* </div> */}
    </div>
</div>
)
}

export function CustomMenu ({setFormations, categoryTitle, setCategoryTitle, listNoFilter}) {
return(
<div className="py-5 bg-[#F6F6F6] hidden z-10 sticky top-0 lg:block">
    <Menu>
        <MenuButton onClick={() => {window?.scrollTo({top: document?.querySelector('#modules').getBoundingClientRect().top + window?.scrollY})}} className="inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {categoryTitle}
            <img src={Open} className='size-4 stroke-white/60' alt='Filter Icon' aria-hidden="true" />
        </MenuButton>
        <Transition  enter="transition ease-out duration-75" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="transition ease-in duration-100" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
            <MenuItems anchor="bottom start" className="absolute left-0 z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                    <button 
                        style={categoryTitle === 'Voir tout' ? {backgroundColor:"rgb(0 0 0 / 0.1)"} : {}}
                        onClick={() => {setFormations(listNoFilter);setCategoryTitle('Voir tout')}} 
                        className="group flex w-full text-sm items-center text-start gap-2 py-1.5 px-3 data-[focus]:bg-black/10">
                        Voir tout
                    </button>
                </MenuItem>
                {dataFilter?.categories?.map(category => 
                    <MenuItem key={category.id}>
                        <button 
                            style={categoryTitle === category.title ? {backgroundColor:"rgb(0 0 0 / 0.1)"} : {}}
                            onClick={() => {setFormations(listNoFilter.filter(formation => formation?.categories?.includes(category.title)));setCategoryTitle(category.title)}} 
                            className="group flex w-full text-sm items-center text-start gap-2 py-1.5 px-3 data-[focus]:bg-black/10">
                            {category.title}
                        </button>
                    </MenuItem>
                )}
            </MenuItems>
        </Transition>
    </Menu>
</div>
)
}
