import { CircularProgress, ThemeProvider, LinearProgress } from "@mui/material"
import { colorTheme } from "./styles/mui";


export function Loading () {
    return (
        <div className="w-full">
            <div className="flex items-center justify-center place-self-center h-[150px]">
                <ThemeProvider theme={colorTheme}>
                    <LinearProgress color="primary" className="w-60"/>
                </ThemeProvider>
            </div>
        </div>
    )
}

export function CircularLoading () {
    return(
        <ThemeProvider theme={colorTheme}>
            <CircularProgress size="1rem" color='primary'/>
        </ThemeProvider>
    )
}
